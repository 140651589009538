import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Index = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const companyLogoSources = [
    data.companyLogosMobile.childImageSharp.fluid,
    {
      ...data.companyLogosDesktop.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Welcome" />
      <Image
        fluid={data.mast.childImageSharp.fluid}
        alt="Me"
        style={{
          maxWidth: `100%`,
          marginBottom: rhythm(1 / 2),
          marginLeft: `auto`,
          marginRight: `auto`,
          marginTop: 0,
          maxHeight: `75vh`,
        }}
      />
      <div
        style={{
          maxWidth: rhythm(44),
          marginLeft: `auto`,
          marginRight: `auto`,
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <h1 style={{ textAlign: `center`, margin: `2rem 0 4rem` }}>
          Latest Showreel
        </h1>
        <div className="video-wrapper" style={{ marginBottom: `2rem` }}>
          <iframe
            src="https://player.vimeo.com/video/208821024?autoplay=0"
            width="100%"
            height="316"
            frameBorder="0"
            title="Crafted Films"
          ></iframe>
        </div>
        <p>
          Brand film, promo, editorial content, recipe or explainer video - you
          name it and I’ll create it!
        </p>
        <p>
          I love tailoring bespoke video solutions - so whether you are a small
          business only just starting out, have already established yourself in
          the market, or are a big corporation looking for more video options in
          your marketing mix, I will work closely with you to create the most
          effective video for your business on message and on budget.
        </p>
        <div style={{ textAlign: `center`, margin: `3rem 0` }}>
          <Link to={`/portfolio`} className="button">
            Portfolio
          </Link>
        </div>
        <section>
          <h1 style={{ textAlign: `center`, margin: `5rem 0 1rem` }}>
            Some of the happy clients <br />I have worked with
          </h1>
          <Image
            fluid={companyLogoSources}
            alt="Client company logos"
            style={{
              position: "relative",
              height: "100%",
              width: "100%",
              marginBottom: rhythm(1),
              marginLeft: `auto`,
              marginRight: `auto`,
              marginTop: 0,
            }}
          />
        </section>
        <section>
          <div className="grid">
            <div className="col col-6 d-flex align-center">
              <div className="quoted">
                92% of marketers who use video say that it's an{" "}
                <strong>important part</strong> of their{" "}
                <em>marketing strategy</em>
              </div>
            </div>
            <div className="col col-6">
              <p>
                Video gives you the chance to speak directly to your audience
                from anywhere in the world, it evokes emotion and can easily be
                shared. It provides businesses with the opportunity to
                effectively communicate who they are and what they stand for.
              </p>
              <p>
                With social media channels playing an increasing role in
                promoting a brand or a cause, it allows them to showcase their
                products in more detail than any other tool available to
                marketing could.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="grid">
            <div className="col col-6">
              <Image
                fluid={data.irisCamera.childImageSharp.fluid}
                alt="Sunset, happy"
                style={{
                  maxWidth: `100%`,
                  margin: 0,
                }}
              />
            </div>
            <div className="col col-6 d-flex align-center">
              <div className="quoted">
                <strong>80%</strong> of video marketers say video has directly
                helped <strong>increase sales</strong>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="grid">
            <div className="col col-6 d-flex align-center">
              <div className="quoted">
                <strong>96%</strong> of people say they've watched an explainer
                video to learn more about a <strong>product or service</strong>
              </div>
            </div>
            <div className="col col-6">
              <p>
                The production process - from initial enquiry to your final film
              </p>
              <p>
                With any enquiries I always try to find out as much as I can
                about your project to fully understand your objectives.
              </p>
              <p>
                I will of course discuss this in detail with you either in
                person or on the phone but if you’re not sure where to start,
                have a think about the seven questions outlined on my ‘Work with
                me’ page. These will determine the type of video and how it
                should be shot/produce best.
              </p>
            </div>
          </div>
        </section>
        <div style={{ textAlign: `center`, margin: `3rem 0 4rem` }}>
          <Link to={`/contact`} className="button">
            Work with me
          </Link>
        </div>
        <section>
          <div className="grid">
            <div className="col col-6">
              <Image
                fluid={data.girlsHappy.childImageSharp.fluid}
                alt="Sunset, happy"
                style={{
                  maxWidth: `100%`,
                  margin: 0,
                }}
              />
            </div>
            <div className="col col-6 d-flex align-center">
              <div>
                <div className="quoted">
                  <strong>68%</strong> of people say they'd most prefer to{" "}
                  <strong>learn about</strong> a <em>new product or service</em>{" "}
                  <strong>by watching a short video</strong>
                </div>
                <p style={{ marginTop: `1rem` }}>
                  Video can build depth to an online presence, it can generate a
                  following of viewers and ulitmately an engaged community by
                  effectively communicating a message or brand identity.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    mast: file(absolutePath: { regex: "/mast-home.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    companyLogosMobile: file(
      absolutePath: { regex: "/crafted-films-company-logos-mobile.jpg/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    companyLogosDesktop: file(
      absolutePath: { regex: "/crafted-films-company-logos-desktop.jpg/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    irisCamera: file(absolutePath: { regex: "/iris-camera.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 660, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    girlsHappy: file(absolutePath: { regex: "/girls-happy.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 660, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
